import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'work-order-details',
  templateUrl: './work-order-details.component.html',
  styleUrls: ['./work-order-details.component.scss']
})
export class WorkOrderDetailsComponent implements OnInit {

  activeTab: string = 'tab1'
  tableData: Array<any> = []

  constructor(private navigationService: NavigationService) { }

  ngOnInit() {

    this.tableData = [
      [
        {
          value: 'Setra Hasselfors GT'
        },
        {
          value: 632
        },
        {
          value: 432,
          link: ''
        },
        {
          value: 3
        },
        {
          value: 0
        },
        {
          value: 0
        },
        {
          value: 0
        }
      ],
      [
        {
          value: 'Hållanders GKL TKL'
        },
        {
          value: 100
        },
        {
          value: 100,
          link: ''
        },
        {
          value: 2
        },
        {
          value: 23
        },
        {
          value: 45
        },
        {
          value: 45
        }
      ],
      [
        {
          value: 'BM Bäckhammar'
        },
        {
          value: 90
        },
        {
          value: 45,
          link: ''
        },
        {
          value: 1
        },
        {
          value: 12
        },
        {
          value: 32
        },
        {
          value: 34
        }
      ],
      [
        {
          value: 'BM alla mott.platser VMR'
        },
        {
          value: 456
        },
        {
          value: 321,
          link: ''
        },
        {
          value: 3
        },
        {
          value: 0
        },
        {
          value: 232
        },
        {
          value: 445
        }
      ]
    ]
  }

}
