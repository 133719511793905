import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../services/navigation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  section1Visible: boolean = true
  section2Visible: boolean = true

  constructor(private navigationService: NavigationService, private router: Router) { 
    
  }

  ngOnInit() {
  }

  onItemClick() {
    this.navigationService.navigationActive = false
  }

  toggleSection(event: Event, sectionNumber: number) {
    event.preventDefault()

    switch (sectionNumber) {
      case 1:
        this.section1Visible = !this.section1Visible
        break
      case 2:
        this.section2Visible = !this.section2Visible
        break
    }

  }

  public isLinkActive(url: string): boolean {
    return (this.router.url == url)
  }

}
