import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { HomeComponent } from './home/home.component'
import { StartComponent } from './start/start.component'
import { PatternLibraryComponent } from './pattern-library/pattern-library.component'
import { ButtonsComponent } from './buttons/buttons.component'
import { WorkOrdersComponent } from './work-orders/work-orders.component'
import { HelpersComponent } from './helpers/helpers.component'


const routes: Routes = [
  { path: '', component: StartComponent },
  { path: 'map', component: StartComponent },
  { path: 'pattern-library', component: PatternLibraryComponent},
  { path: 'helpers', component: HelpersComponent},
  { path: 'buttons', component: ButtonsComponent},
  { path: 'work-orders', component: WorkOrdersComponent},
  
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
