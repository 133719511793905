import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NavigationService } from '../services/navigation.service';
import _ from 'lodash'
import $ from 'cash-dom';

@Component({
  selector: 'load',
  templateUrl: './load.component.html',
  styleUrls: ['./load.component.scss']
})
export class LoadComponent implements OnInit {
  @ViewChild("truckSlider", { static: true }) private truckSliderElem: ElementRef;

  currentSlideNumber: number = 0
  activeTab: string = 'tab1'

  activeStackDropdownId: number
  activeVehicleDropdownId: number

  load: Array<any> = []
  stacks: Array<any> = [1, 2, 3]
  vehicles: Array<any> = [
    {
      id: 1,
      regNo: 'YTD 176',
      type: 'truck',
    },
    {
      id: 2,
      regNo: 'YBG 905',
      type: 'trailer'
    }
  ]
  vehicleDropdownVisible: Array<number> = new Array(this.vehicles.length)

  maxRowCount: number = 0

  constructor(private navigationService: NavigationService) {
    
    this.load = [
      {
        vehicle: {
          id: 1,
          regNo: 'YTD 176',
          type: 'truck',
          stackCount: 2,
          rowCount: 2
        },
        truckLoad: [
          {
            id: 1,
            title: 'Nydala GT 1',
            text: 'AO1234 Skråmmen',
            symbol: 'fas fa-square fa-rotate-45',
            status: 'notified',
            rowSpan: 3,
            gridRowStart: 1,
            gridRowEnd: 3
          },
          {
            id: 2,
            title: 'Nydala GT 2',
            text: 'AO1234 Skråmmen',
            symbol: 'fas fa-square fa-rotate-45',
            status: 'notified',
            rowSpan: 1,
            gridRowStart: 1,
            gridRowEnd: 2
          },
          {
            id: 3,
            title: 'Nydala GT 3',
            text: 'AO1234 Skråmmen',
            symbol: 'fas fa-square fa-rotate-45',
            status: 'unnotified',
            rowSpan: 1,
            gridRowStart: 2,
            gridRowEnd: 3
          },
          // {
          //   id: 4,
          //   title: 'Nydala GT 4',
          //   text: 'AO1234 Skråmmen',
          //   symbol: 'fas fa-square fa-rotate-45',
          //   status: 'unnotified',
          //   rowSpan: 1,
          //   gridRowStart: 3,
          //   gridRowEnd: 4
          // }
        ]
      },
      {
        vehicle: {
          id: 2,
          regNo: 'YBG 905',
          type: 'trailer',
          stackCount: 3,
          rowCount: 2
        },
        truckLoad: [
          {
            id: 4,
            title: 'Barrmassa 1',
            text: 'AO1234 Skråmmen',
            symbol: 'fas fa-circle',
            status: 'unnotified',
            rowSpan: 2,
            gridRowStart: 1,
            gridRowEnd: 3

          },
          {
            id: 5,
            title: 'Barrmassa 2',
            text: 'AO1234 Skråmmen',
            symbol: 'fas fa-circle',
            status: 'unnotified',
            rowSpan: 2,
            gridRowStart: 1,
            gridRowEnd: 3
          },
          {
            id: 6,
            title: null,
            text: null,
            symbol: null,
            status: 'empty',
            rowSpan: 2,
            gridRowStart: 1,
            gridRowEnd: 3
          }
          
        ]
      },
      // {
      //   vehicle: {
      //     id: 3,
      //     regNo: 'YBG 905',
      //     type: 'trailer',
      //     stackCount: 3,
      //     rowCount: 2
      //   },
      //   truckLoad: [
      //     {
      //       title: 'Barrmassa 1',
      //       text: 'AO1234 Skråmmen',
      //       symbol: 'fas fa-circle',
      //       rowSpan: 2,
      //       gridRowStart: 1,
      //       gridRowEnd: 3
      //     },
      //     {
      //       title: 'Barrmassa 2',
      //       text: 'AO1234 Skråmmen',
      //       symbol: 'fas fa-circle',
      //       rowSpan: 2,
      //       gridRowStart: 1,
      //       gridRowEnd: 3
      //     },
      //     {
      //       title: 'Barrmassa 3',
      //       text: 'AO1234 Skråmmen',
      //       symbol: 'fas fa-circle',
      //       rowSpan: 2,
      //       gridRowStart: 1,
      //       gridRowEnd: 3
      //     }
          
      //   ]
      // },
    ]

    

    this.maxRowCount = _.max(_.map(this.load, item => {
      return item.vehicle.rowCount
    }))

  }

  ngOnInit() {
  }


  gridTemplateCalculation(count) {
    let unit = (100 / count) + '% '
    let string = ''
    for (let i = 0; i < count; i++) {
      string = string + unit
    }
    return string
  }

  slideTo(slideNumber) {

    if (slideNumber > -1 && slideNumber < this.load.length) {
      this.currentSlideNumber = slideNumber
    }

    // note: The reason I'm getting the truck-slider element from the DOM directly
    //       here instead of using this.truckSliderElem is that viewChilds
    //       don't work when inside *ngIf or *ngFor. There's possibly workarounds
    //       around this.
    document.querySelector('.truck-slider').scrollTo({
      top: 0,
      left: window.innerWidth * slideNumber,
      behavior: 'smooth'
    });


    // note: This don't work when inside *ngIf or *ngFor
    // this.truckSliderElem.nativeElement.scrollTo({
    //   top: 0,
    //   left: window.innerWidth * slideNumber,
    //   behavior: 'smooth'
    // });
    
  }

  toggleVehicleDropdown(id) {
    if (id == this.activeVehicleDropdownId) {
      this.closeDropdown()
    } else {
      this.closeDropdown()
      this.activeVehicleDropdownId = id
      
      setTimeout(() => {
       $('body').on('click.dropdown', () => {
         this.closeDropdown()
       })
      }, 200)
      
    }
   }

  toggleStackDropdown(id) {
   if (id == this.activeStackDropdownId) {
     this.closeDropdown()
   } else {
    this.closeDropdown()
    this.activeStackDropdownId = id

     setTimeout(() => {
      $('body').on('click.dropdown', () => {
        this.closeDropdown()
      })
     }, 200)
     
   }
  }

  closeDropdown() {
    $('body').off('click.dropdown')
    this.activeStackDropdownId = null
    this.activeVehicleDropdownId = null
  }


}
