import { Component, OnInit, Input } from '@angular/core';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'work-order',
  templateUrl: './work-order.component.html',
  styleUrls: ['./work-order.component.scss']
})
export class WorkOrderComponent implements OnInit {

  selectedTab: String = 'tab1'
  expanded: Boolean = false
  maximized: Boolean = false
  savingComment: Boolean = false
  savedComment: Boolean = false
  
  constructor(private navigationService: NavigationService) {

  }

  ngOnInit() {
  }

  toggleExpanded() {
    this.expanded = !this.expanded
  }

  close() {
    this.navigationService.hideWorkOrder()
    this.maximized = false
  }

  simulateCommentSave(e) {
    e.preventDefault()
    this.savingComment = true
    this.savedComment = false
    
    setTimeout(() => {
      this.savingComment = false
      this.savedComment = true
    }, 2000)
  }

}
