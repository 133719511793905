import { Component, OnInit } from '@angular/core';
import $ from 'cash-dom';
import {indent} from 'indent.js';
import { HighlightService } from '../services/highlight.service';
import { ToastService } from './../services/toast.service';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'pattern-library',
  templateUrl: './pattern-library.component.html',
  styleUrls: ['./pattern-library.component.scss']
})
export class PatternLibraryComponent implements OnInit {

  selectedTab: String = 'tab1'
  selectedTabLarge: String = 'tabLarge1'
  exampleModalActive: boolean = false

  constructor(private highlightService: HighlightService, private toastService: ToastService, private navigationService: NavigationService) { }

  ngOnInit() {
    document.querySelectorAll('.library-example').forEach(elem => {
      this.createCodeExampleFromHtml(elem)
    })

    this.highlightService.highlightAll()
  }

  createCodeExampleFromHtml(elem) {
    let html = 
      elem.innerHTML
      .replace(/<!---->/g, '')
      .replace(/</g, '\n<')
      .replace(/>/g, '>\n')
      .replace(/\n\n/g, '\n')
      .replace(/\n/, '')

      html = indent.js(html, {indentHtmlTag: true, tabString: '  '})
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/ _ngcontent-(.......)=""/g, '')
        

    let container = $(`<pre class="code"><code class="language-markup">${html}</code></pre>`)
    $(elem).after(container)
  } 

  toggleExampleModal() {
    this.exampleModalActive = !this.exampleModalActive
    document.querySelector('html').classList.toggle('modal-mode', this.exampleModalActive)
  }

  addSuccessToast() {
    this.toastService.addToast('success', 'Ett succémeddelande')
  }

  addWarningToast() {
    this.toastService.addToast('warning', 'Ett varningsmeddelande', 'Läs mer', '/map')
  }

  addErrorToast() {
    this.toastService.addToast('error', 'Ett felmeddelande')
  }

  addWarningToastWithDuration() {
    this.toastService.addToast('warning', 'Ett varningsmeddelande', 'Läs mer', '/map', 3000)
  }

}
