import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @ViewChild("searchInput", { static: true }) private searchInput: ElementRef;
  
  private active: boolean = false
  private searching: boolean = false
  private searchResult: Array<any> = []

  constructor(private navigationService: NavigationService) { 

  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.searchInput.nativeElement.focus()
  }

  close() {
    this.navigationService.toggleSearch()
  }

  search(e) {
    e.preventDefault()
    this.searching = true
    this.searchResult = []

    setTimeout(() => {
      this.searching = false

      for (var i = 0; i < 10; i++) {
        this.searchResult.push({
          url: '/pattern-library',
          title: `Sökresultat ${i}`,
          description: 'Något mer om denna sökning'
        })
      }
    }, 2000)
  }

}
