import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit, AfterViewChecked {

  highlighted: boolean = false;

  constructor(private navigationService: NavigationService) {
  }

  ngAfterViewChecked() {
    // if (!this.highlighted) {
    //   this.highlightService.highlightAll();
    //   this.highlighted = true;
    // }
  }

  ngOnInit() {

  }
  
}
