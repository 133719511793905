import { Component } from '@angular/core';
import { Router, RouterEvent, NavigationEnd, ActivatedRoute } from '@angular/router'
import { NavigationService } from './services/navigation.service';
import { ToastService } from './services/toast.service';
import $ from 'cash-dom';
import { trigger, state, group, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('300ms ease', style({ 
              opacity: 1 
            }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('300ms ease-in', style({ opacity: 0 }))
          ]
        )
      ]
    ),
  ]
})
export class AppComponent {
  title = 'prolog-framework';

  private fragment: string;

  constructor(private router: Router, private route: ActivatedRoute, private navigationService: NavigationService, private toastService: ToastService) {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        try {
          document.querySelector('#' + this.fragment).scrollIntoView();
        } catch (e) { 

        }
      }
    })
  }

  ngOnInit() {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });

    // Makes navbar sticky when the page scrolls down
    $(window).on('scroll.navbar', () => {
      if (window.innerWidth < 740) {
        return
      }
      const siteHeader = document.querySelector('.siteheader')
      const body = document.querySelector('body')
      body.classList.toggle('has-fixed_navbar', window.scrollY > siteHeader.clientHeight)
    })
  }

  ngOnDestroy() {
    $(window).off('scroll.navbar')
  }

}
