import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() label: String
  @Input() size: String = 'medium'
  @Input() inline: Boolean = false
  
  constructor() { }

  ngOnInit() {
  }

}
