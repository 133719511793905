import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../services/navigation.service';
import $ from 'cash-dom'

@Component({
  selector: 'navbar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  toggleIsActive = false
  tagsResizeTimeout = null

  constructor(private navigationService: NavigationService) { 
    
  }

  ngOnInit() {
  }


  ngAfterViewInit() {
    this.adjustTags()

    $(window).on('resize.tags', () => {
      if (this.tagsResizeTimeout) {
        clearTimeout(this.tagsResizeTimeout)
      }
      this.tagsResizeTimeout = setTimeout(() => {
        this.adjustTags()
      }, 100)
      
    })
  }

  adjustTags() {
    let screenWidth = window.innerWidth
    let navbarStart = $('.navbar-start')
    let navbarStartWidth = screenWidth - $('.navbar-actions').width()
    navbarStart.css({
      width: (navbarStartWidth) + 'px'
    })

    let padding = 248
    let availableWidth = navbarStart.innerWidth()  - $('.navbar-tags-label').width() - $('.navbar-tags-action').width() - padding

    let totalTagWidth = 0
    let hiddenTagsCount = 0

    let tagItems = navbarStart.find('.tags-item')
    tagItems.removeAttr('style')

    tagItems.each((index, item) => {
      totalTagWidth += $(item).width() + 16
      if (totalTagWidth > availableWidth) {
        $(item).hide()
        hiddenTagsCount++
      }
    })
    
    
    navbarStart.find('.navbar-tags-count').remove()
    if (hiddenTagsCount > 0) {
      navbarStart.find('.tags').append(`<span class="navbar-tags-count">+${hiddenTagsCount} till</span>`)
    }
  }

  toggleNavigation() {
    this.navigationService.navigationActive = !this.navigationService.navigationActive
  }

  toggleFilter() {
    this.navigationService.toggleFilter()
  }

  toggleSearch() {
    this.navigationService.toggleSearch()
  }

}