import { Injectable } from '@angular/core';
import $ from 'cash-dom';
import { trigger } from '@angular/animations';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  navigationActive: boolean = false
  workOrderActive: boolean = false
  filterActive: boolean = false
  searchActive: boolean = false
  reportActive: boolean = false
  loadActive: boolean = false

  darkTheme: boolean = false

  mapNavVisible: boolean = false
  mapNavExpanded: boolean = false
  mapPanelActive: boolean = false

  workOrder: any = null
  workOrderDetailsActive: boolean = false

  exampleModalActive: boolean = false

     
  constructor() { }

  toggleNavigation() {
    let active = !this.navigationActive
    this.resetAll()
    this.navigationActive = active
  }

  toggleFilter() {
    let active = !this.filterActive
    this.resetAll()
    this.filterActive = active
  }

  toggleSearch() {
    let active = !this.searchActive
    this.resetAll()
    this.searchActive = active
  }

  showWorkOrder(location) {
    this.workOrder = location
    this.resetAll()
    this.workOrderActive = true
  }

  hideWorkOrder() {
    this.workOrder = false
    this.workOrderActive = false
  }

  toggleReport() {
    let active = !this.reportActive
    this.resetAll()
    this.reportActive = active
  }

  toggleLoad() {
    let active = !this.loadActive
    this.resetAll()
    this.loadActive = active
  }

  toggleWorkOrderDetails() {
    let active = !this.workOrderDetailsActive
    this.resetAll()
    this.workOrderDetailsActive = active

    this.setModalMode(active)
  }

  private resetAll() {
    this.navigationActive = false
    this.workOrderActive = false
    this.filterActive = false
    this.searchActive = false
    this.reportActive = false
    this.loadActive = false
    this.workOrderDetailsActive = false
  }

  setModalMode(active) {
    $('html').toggleClass('modal-mode', active)
  }


  toggleTheme() {
    this.darkTheme = !this.darkTheme
    let classList = document.querySelector('html').classList

    if (this.darkTheme) {
      classList.remove('theme--light')
      classList.add('theme--dark')
    } else {
      classList.remove('theme--dark')
      classList.add('theme--light')
    }
    
  }




  // ====================================================
  // Tooltip methods
  // ====================================================

  showTooltip(e, content) {
    let target = $(e.target);

    if ($('[data-has-tooltip]').length > 0) {
      let willReturn = (e.type == 'click' && target.attr('data-has-tooltip'))
      this.hideTooltip()
      if (willReturn) {
        return
      }
    }

    target.attr('data-has-tooltip', 'true')

    let tooltip = $(this.createTooltip(content))
    
    target.before(tooltip)

    let bodyRect = document.body.getBoundingClientRect()
    let targetRect = e.target.getBoundingClientRect();
    let offsetTop = targetRect.top - bodyRect.top;
    let offsetLeft = targetRect.left - bodyRect.left;
    let offsetRight = targetRect.right - bodyRect.left;
    let top = offsetTop - tooltip.outerHeight() - 1;
    let left = offsetLeft + ((offsetRight - offsetLeft) / 2) - (tooltip.outerWidth() / 2);

    tooltip
      .css({
        top: `${top}px`,
        left: `${left}px`
      })
    
    

    if (e.type == 'click') {
      setTimeout(() => {
        $('body').off('click.tooltip').on('click.tooltip', () => {
          this.hideTooltip()
        })

      }, 200)
    } else if (e.type == 'mouseover') {
      target.on('mouseout.tooltip', () => {
        this.hideTooltip(target)
      })
    }
  }

  hideTooltip(target = null) {
    $('.tooltip').remove()
    $('body').off('click.tooltip')
    $('[data-has-tooltip]').removeAttr('data-has-tooltip')

    if (target) {
      target.off('mouseout.tooltip')
    }
  }

  createTooltip(content) {
    return `<div class="tooltip">
      <div class="tooltip-inner">${content}</div>
      <span class="tooltip-caret"></span>
    </div>`  
  }
}
