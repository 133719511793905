import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HighlightService } from './services/highlight.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { SiteheaderComponent } from './siteheader/siteheader.component';
import { StartComponent } from './start/start.component';
import { HomeComponent } from './home/home.component';
import { ModalComponent } from './modal/modal.component';
import { PatternLibraryComponent } from './pattern-library/pattern-library.component';
import { NavigationComponent } from './navigation/navigation.component';
import { ButtonsComponent } from './buttons/buttons.component';
import { MapComponent } from './map/map.component';
import { WorkOrdersComponent } from './work-orders/work-orders.component';
import { WorkOrderComponent } from './work-order/work-order.component';
import { FilterComponent } from './filter/filter.component';
import { SearchComponent } from './search/search.component';
import { ReportComponent } from './report/report.component';
import { ToastComponent } from './toast/toast.component';
import { LoaderComponent } from './loader/loader.component';
import { HelpersComponent } from './helpers/helpers.component';
import { LoadComponent } from './load/load.component';
import { WorkOrderDetailsComponent } from './work-order-details/work-order-details.component';




@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    SiteheaderComponent,
    StartComponent,
    HomeComponent,
    ModalComponent,
    PatternLibraryComponent,
    NavigationComponent,
    ButtonsComponent,
    MapComponent,
    FilterComponent,
    WorkOrdersComponent,
    WorkOrderComponent,
    SearchComponent,
    ReportComponent,
    ToastComponent,
    LoaderComponent,
    HelpersComponent,
    LoadComponent,
    WorkOrderDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  providers: [
    HighlightService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
