import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  filters: Array<any> = [
    {
      id: 'transportbefordrad-till',
      title: 'Transportbefordrad till', 
      active: true
    },
    {
      id: 'arbetsorder-placerad',
      title: 'Arbetsorder placerad', 
      active: false
    },
    {
      id: 3,
      title: 'Virkets destination', 
      active: false
    },
    {
      id: 4,
      title: 'Handelssortiment', 
      active: false
    },
    {
      id: 5,
      title: 'Produktionsområde', 
      active: false
    },
    {
      id: 6,
      title: 'Maskinlag', 
      active: false
    },
    {
      id: 7,
      title: 'Transportsätt', 
      active: false
    },
    {
      id: 8,
      title: 'Virkesålder', 
      active: false
    },
    {
      id: 9,
      title: 'Vägbärighet', 
      active: false
    },
    {
      id: 10,
      title: 'Anskaffningsform', 
      active: false
    },
  ]

  productionAreas: Array<any> = [
    {
      id: 1,
      name: 'Hej'
      
    },
    {
      id: 2,
      name: 'Hallå'
      
    }
  ]

  selectedFilter: any = null //this.filters[0]

  constructor(private navigationService: NavigationService) { 
    
  }

  ngOnInit() {
  }

  showDetails(e, filter) {
    e.preventDefault()
    this.selectedFilter = filter
  }

  showList(e) {
    e.preventDefault()
    this.selectedFilter = null
  }

  close() {
    this.navigationService.toggleFilter()
  }

}
