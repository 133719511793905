import { Injectable } from '@angular/core';
import _ from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toastList: Array<any> = []

  constructor() {}

  addToast(type, message, linkText = null, linkUrl = null, duration = null) {
    let id = this.guid()
    this.toastList.push({
      id,
      type,
      message,
      linkText,
      linkUrl,
      duration
    })

    // Remove success toasts after 10 seconds
    if (duration) {
      setTimeout(() => {
        this.removeToast(id)
      }, duration)
    } else if (type == 'success') {
      setTimeout(() => {
        this.removeToast(id)
      }, 10000)
    }
  }

  removeToast(id) {
    let index = _.findIndex(this.toastList, {'id': id})
    if (index > -1) {
      // note: Get the element for the toast, remove the is-active class from it before removing it after 250ms. 
      //        We do this for the out animation
      let toastElem = document.getElementById('toast_' + id)
      if (toastElem) {
        toastElem.classList.remove('is-active')
        setTimeout(() => {
          this.toastList.splice(index, 1)
        }, 250)
      } else {
        this.toastList.splice(index, 1)
      }
    }
  }

  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }  
}
