import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../services/navigation.service';
import $ from 'cash-dom';

@Component({
  selector: 'work-orders',
  templateUrl: './work-orders.component.html',
  styleUrls: ['./work-orders.component.scss']
})
export class WorkOrdersComponent implements OnInit {
  tableData: Array<any> = []
  activeDropdownIndex: number

  constructor(private navigationService: NavigationService) { }

  ngOnInit() {

    this.tableData = [
      [
        { value: 'AB1344'},
        { value: 'Ledhestra'},
        { value: '45583493'},
        { value: '82222'},
        { value: '7620'},
        { value: 'Ja'},
        { value: 56},
        { value: 24},
        { value: '21 dgr'},
        { value: true, type: 'comments'},
        { value: 'Skördn. pl. start (2020-03-08)', type: 'status', status: 'harvesting'},
        { value: '3 mar - 17 mar'}
      ],
      [
        { value: 'AB1344'},
        { value: 'Ledhestra'},
        { value: '45583493'},
        { value: '82222'},
        { value: '7620'},
        { value: 'Ja'},
        { value: 56},
        { value: 24},
        { value: '21 dgr'},
        { value: true, type: 'comments'},
        { value: 'Skördn. pl. start (2020-03-08)', type: 'status', status: 'harvesting'},
        { value: '3 mar - 17 mar'}
      ],
      [
        { value: 'AB1344'},
        { value: 'Ledhestra'},
        { value: '45583493'},
        { value: '82222'},
        { value: '7620'},
        { value: 'Ja'},
        { value: 56},
        { value: 24},
        { value: '21 dgr'},
        { value: true, type: 'comments'},
        { value: 'Skördn. pl. start (2020-03-08)', type: 'status', status: 'harvesting'},
        { value: '3 mar - 17 mar'}
      ],
      [
        { value: 'AB1344'},
        { value: 'Ledhestra'},
        { value: '45583493'},
        { value: '82222'},
        { value: '7620'},
        { value: 'Ja'},
        { value: 56},
        { value: 24},
        { value: '21 dgr'},
        { value: true, type: 'comments'},
        { value: 'Skördn. pl. start (2020-03-08)', type: 'status', status: 'harvesting'},
        { value: '3 mar - 17 mar'}
      ],
      [
        { value: 'AB1344'},
        { value: 'Ledhestra'},
        { value: '45583493'},
        { value: '82222'},
        { value: '7620'},
        { value: 'Ja'},
        { value: 56},
        { value: 24},
        { value: '21 dgr'},
        { value: true, type: 'comments'},
        { value: 'Skördn. pl. start (2020-03-08)', type: 'status', status: 'harvesting'},
        { value: '3 mar - 17 mar'}
      ],
      [
        { value: 'AB1344'},
        { value: 'Ledhestra'},
        { value: '45583493'},
        { value: '82222'},
        { value: '7620'},
        { value: 'Ja'},
        { value: 56},
        { value: 24},
        { value: '21 dgr'},
        { value: true, type: 'comments'},
        { value: 'Skördn. pl. start (2020-03-08)', type: 'status', status: 'harvesting'},
        { value: '3 mar - 17 mar'}
      ]
    ]

  }

  toggleDropdown(e, index) {
    e.preventDefault()
    e.stopPropagation()
    
    if (index == this.activeDropdownIndex) {
      this.closeDropdown()
    } else {
      this.closeDropdown()
      this.activeDropdownIndex = index
 
      setTimeout(() => {
       $('body').off('click.dropdown').on('click.dropdown', () => {
         this.closeDropdown()
       })
      }, 200)
      
    }
   }
 
   closeDropdown() {
     $('body').off('click.dropdown')
     this.activeDropdownIndex = null
   }

}
