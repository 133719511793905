import { Component, OnInit, AfterViewChecked } from '@angular/core';
//import $ from 'cash-dom';

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss']
})
export class ButtonsComponent implements OnInit, AfterViewChecked {

  highlighted: boolean = false;
  
  constructor() {
  }

  ngAfterViewChecked() {
    //this.generateCodeExamples()
    // if (!this.highlighted) {
    //   this.highlightService.highlightAll()
    //   this.highlighted = true
    // }
  }

  ngOnInit() {
  }

  generateCodeExamples() {
//     $('.example').each(function(example) {
//       let that = $(this)
     
//       let codeStr = 
//       $(this)
//         .unwrap()
//         .html()
//         .replace(/ _ngcontent-(......."")/g, '')
//         .replace(/</g, '&lt;')
//         .replace(/>/g, '&gt;')
//         .replace(/\s/g, '&nbsp;')

//       let codeExample = `<h3>Kodeexempel</h3>
// <pre class="example-code"><code class="language-markup">${codeStr}</code></pre>`
//       that.after(codeExample)

    // })
  }

}
