import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
  
  @Input() id: String
  @Input() type: String
  @Input() message: String
  @Input() linkText: String
  @Input() linkUrl: String

  @Output() remove = new EventEmitter<any>(); 
  active: Boolean = false

  constructor() { }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.active = true
    }, 50)
  }

  close() {
    this.remove.emit(this.id)
  }

}
